/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";

import Seo from "../components/Seo";
import Wrapper from "../components/Wrapper";
import { H1, H2, H4 } from "../components/text";

import BackgroundMobileImg from "../images/background-mobile.jpg";
import BackgroundImg from "../images/background.jpg";
import { Link } from "gatsby";

// markup
const NotFoundPage = withTheme(({ theme }) => {
  return (
    <main
      css={css`
        text-align: center;
        background: url(${BackgroundMobileImg}) top center no-repeat;
        /* background-size: 100% auto; */
        padding-top: 36px;
        overflow: hidden;

        ${theme.funcs.mediaquery(
          "xl",
          `background-image: url(${BackgroundImg});
            // background-size: initial;
            padding-top: 76px;
          `
        )}
      `}
    >
      <Seo />
      <Wrapper>
        <H4
          as="p"
          css={css`
            text-transform: uppercase;
            letter-spacing: 0.065em;
            ${theme.funcs.mediaquery(
              "xl",
              `margin-bottom: 10px;
          `
            )}
          `}
        >
          Willkommen bei
        </H4>
        <H1>TKKG – Mein Abenteuer</H1>
        <H2
          as="p"
          css={css`
            margin: 20px 0 10px;
            ${theme.funcs.mediaquery(
              "xl",
              `margin: 75px 0 25px;
          `
            )}
          `}
        >
          Leider wurde die Seite nicht gefunden.
          <br />
          <Link to="/">Zurück zur Startseite</Link>
        </H2>
      </Wrapper>
    </main>
  );
});

export default NotFoundPage;
