/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";

const components = [
  { name: "H1", tag: "h1", style: "headline1" },
  { name: "H2", tag: "h2", style: "headline2" },
  { name: "H3", tag: "h3", style: "headline3" },
  { name: "H4", tag: "h4", style: "headline4" },
  { name: "P2", tag: "p", style: "bodySmall" },
];

const createComponent = ({ tag, style }) =>
  withTheme(({ theme, children, as: Tag = tag, ...props }) => (
    <Tag
      css={css`
        ${theme.textStyles[style]}
      `}
      {...props}
    >
      {children}
    </Tag>
  ));

const { H1, H2, H3, H4, P2 } = components.reduce((acc, cur) => {
  const Comp = createComponent(cur);
  Comp.displayName = cur.name;
  return { ...acc, [cur.name]: Comp };
}, {});
export { H1, H2, H3, H4, P2 };
